import React, { useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Typography } from "@material-ui/core"
import "maplibre-gl/dist/maplibre-gl.css"
import ReactMapboxGl, { Layer, Source, ZoomControl, Popup } from "react-mapbox-gl"

let Map = false

if (typeof window !== `undefined` && process.env.GATSBY_MAPBOX) {
  Map = ReactMapboxGl({
    accessToken: process.env.GATSBY_MAPBOX
  })
}

const RetailerHeatmap = (props) => {
  const {
    data: {
      allWpRetailer: { nodes: retailers }
    }
  } = props

  const [popup, setPopup] = useState(null)

  const onLoad = (map) => {
    if (map) {
      // Center map over North America
      map.fitBounds(
        [
          [-141, 68],
          [-54, 26]
        ],
        { duration: 0 }
      )
    }
  }

  return (
    <Container>
      {typeof window !== "undefined" && (
        <Map
          // eslint-disable-next-line
          style="mapbox://styles/mapbox/streets-v8"
          onStyleLoad={onLoad}
          maxZoom={18}
          movingMethod="jumpTo"
          onClick={() => setPopup(null)}
        >
          <ZoomControl />

          <Source
            id="retailer"
            geoJsonSource={{
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: retailers
                  .filter(
                    (o) =>
                      !!o?.Retailer?.googleMap &&
                      o.Retailer.googleMap.longitude !== 0 &&
                      o.Retailer.googleMap.latitude !== 0
                  )
                  .map((o) => {
                    return {
                      type: "Feature",
                      properties: { title: o.title, address: o.Retailer.googleMap.streetAddress },
                      geometry: {
                        type: "Point",
                        coordinates: [o.Retailer.googleMap.longitude, o.Retailer.googleMap.latitude, 0.0]
                      }
                    }
                  })
              }
            }}
          />

          <Layer id="retailer-heat" type="heatmap" sourceId="retailer" maxZoom={9} />

          <Layer
            id="retailer-point"
            type="circle"
            sourceId="retailer"
            minZoom={9}
            paint={{
              "circle-stroke-color": "white",
              "circle-stroke-width": 1
            }}
            onClick={(e) =>
              setPopup({
                coordinates: e.features[0].geometry.coordinates,
                title: e.features[0].properties.title,
                address: e.features[0].properties.address
              })
            }
          />

          {popup && (
            <Popup
              coordinates={popup?.coordinates || [0, 0]}
              offset={{ "bottom-left": [12, -38], bottom: [0, -10], "bottom-right": [-12, -38] }}
            >
              <Typography variant="h4" children={popup?.title} />
              <Typography children={popup?.address} />
            </Popup>
          )}
        </Map>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 600px;

  .mapboxgl-map {
    height: 100%;
  }
`

export const CollectionQuery = graphql`
  query RetailerHeatmapTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      acfModules {
        hideSidebar
        hideSearch
      }
    }
    allWpRetailer(filter: { Retailer: { googleMap: { streetAddress: { ne: "" } } } }) {
      nodes {
        id
        databaseId
        title
        Retailer {
          googleMap {
            latitude
            longitude
            streetAddress
          }
        }
      }
    }
  }
`

export default RetailerHeatmap
